<template>
  <div id="account">
    My Account
  </div>
</template>

<script>
    export default {
      name: "Account",
      computed:{
        get_user(){
          return this.$store.getters.get_user;
        },
        isAuthenticated() {
          //return this.$localStorage.get('accessToken');
          return this.$store.getters.isAuthenticated;
        },
      },
      created() {
        console.log(this.$route);
        if (this.$route.path && this.$route.path === '/account/verify' && this.$route.params) {
          if (this.$route.query && this.$route.query.username && this.$route.query.validation) {
            this.$router.replace('/verification/?username='+ this.$route.query.username + '&validation=' + this.$route.query.validation);
          } else {
            this.$router.replace('/');
          }
        }
      },
      mounted() {

      }

    }
</script>

<style scoped>

</style>
